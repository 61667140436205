import React, { useEffect, useMemo, useState } from 'react'

import { Link } from 'react-router-dom'

import { isMobile } from 'react-device-detect'
import { Container, Content } from './styles.mobile'
import logo from '../../assets/imagens/logo.svg'

import ModalMenu from '../ModalMenu'

interface IProps {
  pageName?: string
}

const Menu: React.FC<IProps> = ({ pageName }: IProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const screenActive = useMemo(() => {
    return pageName
  }, [pageName])
  useEffect(() => {
    document.body.style.overflow = 'unset'
  }, [])

  return (
    <>
      <Container>
        <Content>
          <div className="menu_top">
            <Link to="/">
              {isMobile && (
                <img src={logo} alt="logo" width={150} height={75} />
              )}
              {!isMobile && (
                <img src={logo} alt="logo" width={200} height={100} />
              )}
            </Link>
            <button
              type="button"
              className="menu-toggle"
              onClick={() => {
                setModalIsOpen(true)
                document.body.style.overflow = 'hidden'
              }}
            >
              <div className="one" />
              <div className="two" />
              <p className="nameMenu">menu</p>
            </button>
          </div>
        </Content>
      </Container>
      <ModalMenu
        modalIsOpen={modalIsOpen}
        screen={screenActive}
        closeModal={() => {
          setModalIsOpen(false)
          document.body.style.overflow = 'unset'
        }}
      />
    </>
  )
}

Menu.defaultProps = {
  pageName: '',
}

export default Menu
