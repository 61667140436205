import React from 'react'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'

import { Container, Title, SubTitle, Description } from './styles'

const items = [
  <Container>
    <Title>Flavia Alessandra</Title>
    <SubTitle>Atriz</SubTitle>
    <Description>
      &quot;Posso dizer que a evva comunicação foi incrível em ter acreditado e
      apostado em um projeto que iniciamos durante a pandemia para ajudar
      refugiados que estavam em situação ainda mais adversa, e com o bazar
      online que foi todo elaborado e formatado pela agência, foi possível
      ajudar quase mil famílias, e ainda conseguimos promover um mega bazar
      presencial no fim de 2022, onde conseguimos fazer uma arrecadação
      histórica que contribuiu ainda mais com a IKMR. Ou seja, vocês são
      incríveis, fazem um trabalho que vai muito além do PR com o núcleo de
      novos negócios, e espero termos mais projetos num futuro próximo&quot;
    </Description>
  </Container>,
  <Container>
    <Title>Julia Konrad</Title>
    <SubTitle>Atriz</SubTitle>
    <Description>
      &quot;Trabalhar com aquilo que se acredita é fundamental, e, na minha
      experiência, este é um dos pilares da evva. Toda a dedicação, cuidado,
      expertise e profissionalismo na construção de estratégia se reflete no
      resultado. Poder contar com esse time é um grande privilégio, e faz toda
      diferença&quot;
    </Description>
  </Container>,
  <Container>
    <Title>Karina Soeiro</Title>
    <SubTitle>Farmacêutica e desenvolvedora</SubTitle>
    <SubTitle>de dermocosméticos</SubTitle>
    <Description>
      &quot;O trabalho da evva fez uma grande diferença na minha carreira
      profissional. Com elas, eu consegui transmitir ainda mais credibilidade no
      meu trabalho, e agora sou reconhecida, não só na minha região, mas
      nacionalmente. Acredito que boa parte desse sucesso se deve a parceria que
      tenho com a evva há tanto tempo. Eu costumo dizer que várias coisas eu
      pensaria em tirar da minha vida se eu estivesse passando por uma crise,
      mas a evva eu quero pra sempre&quot;
    </Description>
  </Container>,
  // <Container>
  //   <Title>Julia Konrad</Title>
  //   <SubTitle>Atriz</SubTitle>
  //   <Description>
  //     &quot;Trabalhar com aquilo que se acredita é fundamental, e, na minha
  //     experiência, este é um dos pilares da evva. Toda a dedicação, cuidado,
  //     expertise e profissionalismo na construção de estratégia se reflete no
  //     resultado. Poder contar com esse time é um grande privilégio, e faz toda
  //     diferença&quot;
  //   </Description>
  // </Container>,
]

const Pagination = (): JSX.Element => {
  return (
    <AliceCarousel
      mouseTracking
      items={items}
      disableButtonsControls
      autoWidth
    />
  )
}
export default Pagination
