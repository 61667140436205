import React, { useEffect, useState } from 'react'
import { CgMathPlus, CgMathMinus } from 'react-icons/cg'
import { isMobile } from 'react-device-detect'
import { Container, Content, Perfil, Descricao, Frase } from './styles'

import MenuMobile from '../../components/Menu/mobile'
import Footer from '../../components/Footer'
import image1 from '../../assets/imagens/sobre/foto-cidinha.png'
import image2 from '../../assets/imagens/sobre/foto-analu.png'

const Sobre: React.FC = () => {
  const [openDescription1, setOpenDescription1] = useState(false)
  const [openDescription2, setOpenDescription2] = useState(false)

  const toggleClass = (index: number): void => {
    if (index === 1) setOpenDescription1(!openDescription1)
    if (index === 2) setOpenDescription2(!openDescription2)
  }

  const handleScroll = (): void => {
    const textPath = document.querySelector('#text-path')

    const h = document.documentElement
    const b = document.body
    const st = 'scrollTop'
    const sh = 'scrollHeight'
    const soma = isMobile ? 500 : 1500
    const multi = isMobile ? 30 : 50

    const percent =
      ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100
    textPath?.setAttribute('startOffset', String(-percent * multi + soma))
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <Container>
      <MenuMobile pageName="sobre" />
      <Content>
        <h1>sobre</h1>
        <div className="boxTexto">
          <p className="textoDestaque">
            Na contramão do mercado, evva simboliza pioneirismo e um jeito novo
            de desbravar uma comunicação que está em constante transformação.
            Nossa proposta é oferecer um trabalho de assessoria de imprensa e
            relações públicas com um olhar externo atento e estratégico, que
            gera resultados e constrói
            <b> IMAGEM, com REPUTAÇÃO e CREDIBILIDADE. </b>
            Entendemos que o trabalho precisa ser personalizado, planejado e
            único. Comunicação eficiente, criativa e assertiva que gera mais que
            visibilidade na mídia, constrói histórias e humaniza marcas e
            pessoas.
          </p>

          <p className="textoDestaque">
            O mercado editorial mudou, se transformou e deu lugar a uma
            comunicação mais dinâmica, humana e estratégica. Com fundadoras com
            mais de 10 anos de experiência no mercado de assessoria de imprensa
            e relações públicas, atuantes nos mais diversos segmentos (moda,
            cultura, música, negócios, decoração, varejo, saúde, beleza e
            entretenimento) e com vasta experiência em gestão e prevenção de
            crise, evva traz bagagem e método de trabalho pioneiro e adaptado às
            mudanças da comunicação.
          </p>
        </div>
      </Content>
      <div className="quemfaz">
        <svg
          width="100%"
          height={isMobile ? '100px' : '500px'}
          viewBox="0 0 1098.72 89.55"
        >
          <path
            className="text-on-path__curve"
            d="M.13 40.06s340-89 721 0 720-1 720-1"
            fill="none"
            stroke="none"
            id="curve"
          />
          <text width="100%">
            <textPath
              id="text-path"
              alignmentBaseline="auto"
              xlinkHref="#curve"
              startOffset={isMobile ? '200px' : '600px'}
            >
              quem faz quem faz quem faz quem faz quem faz quem faz
            </textPath>
          </text>
        </svg>
      </div>

      <div className="perfil">
        <Perfil>
          <Content>
            <Descricao>
              <div>
                <img src={image1} alt="image1" />
              </div>
              <div className="box">
                <p className="nome">Cidinha Santos</p>

                <div className="cargo">
                  <p className="funcao">CEO-founder </p>

                  <p className="descricao_funcao">
                    (jornalista e assessora de imprensa)
                  </p>
                </div>

                <div
                  className={
                    openDescription1
                      ? 'box_descricao complete'
                      : 'box_descricao'
                  }
                >
                  <p className="descricao">
                    É formada em jornalismo e atua na área de comunicação com a
                    imprensa há mais de 10 anos. Em sua trajetória, passou por
                    grandes agências de comunicação, atendendo marcas e empresas
                    como: Globo SP, Valor Econômico, PepsiCo, TAG Heuer, Uber,
                    BCG (The Boston Consulting Group), Tidal, Fabiana Milazzo,
                    Hays, Porto Seguro, entre outros. De 2016 a 2019 atuou como
                    consultora e diretora de comunicação, profissionalizando os
                    processos de assessoria de imprensa e relações públicas da
                    agência de PR responsável pela imagem de nomes, como: Pabllo
                    Vittar, Luísa Sonza, Claudia Leitte, Cleo, Sophia Abrahão,
                    Ferrugem, Ludmilla, Marco Luque, entre outros. Com forte
                    background em gestão e prevenção de crise, possui diversos
                    cases na área e na evva comunicação implementou o comitê de
                    gestão de crise.
                  </p>
                </div>

                <button onClick={() => toggleClass(1)} type="button">
                  {!openDescription1 && (
                    <CgMathPlus size={44} color="#f2ae2e" />
                  )}
                  {openDescription1 && (
                    <CgMathMinus size={44} color="#3703A6" />
                  )}
                </button>
              </div>
            </Descricao>

            <Frase>
              <p>
                “Ao longo da minha carreira desenvolvi um método de trabalho
                regado por diferenciais que unem expertise de mercado,
                estratégia e relacionamento com a imprensa que, por sua vez,
                geram resultados que constroem reputação, visibilidade e
                imagem’’.
              </p>
            </Frase>
          </Content>
        </Perfil>

        <Perfil>
          <Content>
            <Descricao>
              <div>
                <img src={image2} alt="image2" />
              </div>
              <div className="box">
                <p className="nome2">Analú barbosa</p>
                <div className="cargo">
                  <p className="funcao">Sócia-diretora</p>

                  <p className="descricao_funcao">
                    (assessora de imprensa e relações públicas)
                  </p>
                </div>
                <div
                  className={
                    openDescription2
                      ? 'box_descricao complete'
                      : 'box_descricao'
                  }
                >
                  <p className="descricao">
                    Jornalista com mais de 10 anos de experiência na área de
                    assessoria de imprensa e relações públicas, com vivência em
                    segmentos de: moda, beleza, saúde, turismo, gastronomia,
                    cultura e negócios. Atuou com marcas como: Blue Tree Hotels,
                    Pestana Hotéis, Ibis, do bem, Organomix, Suplicy Cafés, Hair
                    Brasil, BirkenStock, Grupo Dass (Fila e Tryon), Nivea, HBO,
                    Miss Brasil BE Emotion, Tidal, MOB, Les Cinq Gym, Mynd e
                    Music2, Suba, Clínica Cristiane Coelho Ognibene, Dr. David
                    Di Sessa, Mateus Verdelho, CAJUBRASIL, Dra. Adriana Cairo,
                    entre outros. No segmento cultural foi responsável pela
                    inauguração da Japan House no Brasil, atuou com o Museu
                    Catavento e Museu do Futebol, nos musicais Thriller Live, We
                    Will Rock You e A Paixão Segundo Nelson, e na Exposição O
                    Corpo é a Casa de Erwin Wurn no CCBB São Paulo. Especialista
                    em Relações Públicas 2.0, cria parcerias entre marcas e com
                    formadores de opinião para construção e posicionamento de
                    imagem.
                  </p>
                </div>
                <button onClick={() => toggleClass(2)} type="button">
                  {!openDescription2 && (
                    <CgMathPlus size={44} color="#3703A6" />
                  )}
                  {openDescription2 && (
                    <CgMathMinus size={44} color="#3703A6" />
                  )}
                </button>
              </div>
            </Descricao>

            <Frase>
              <p className="last">
                “O mercado evoluiu e acompanhando este desenvolvimento criei
                expertise para dialogar não apenas com veículos de comunicação e
                jornalistas, mas com empresas que possuem sinergia, e
                influenciadores em diversos segmentos para construção e
                posicionamentos de marcas e pessoas.”
              </p>
            </Frase>
          </Content>
        </Perfil>
        <Footer />
      </div>
    </Container>
  )
}

export default Sobre
