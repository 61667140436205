import React from 'react'

import { Container, Content } from './styles'

import MenuMobile from '../../components/Menu/mobile'
import Footer from '../../components/Footer'

const Contato: React.FC = () => {
  return (
    <Container>
      <MenuMobile pageName="contato" />
      <Content>
        <h1>contato</h1>

        <div className="box_contato">
          <div className="box_contato_1">
            <a href="tel:+55 11 27741387">
              <p>+55 11 2774.1387</p>
            </a>

            <a href="mailto:contato@evvacomunicacao.com">
              <p>contato@evvacomunicacao.com</p>
            </a>
          </div>
          <div className="box_contato_2">
            <a
              href="https://goo.gl/maps/Y1meuVzLKykm7cXg9"
              target="_blank"
              rel="noreferrer"
            >
              <p>Rua Dr. Luiz Migliano 1986, sala 414</p>
              <p>São Paulo - SP</p>
            </a>
          </div>
        </div>
      </Content>
      <Footer />
    </Container>
  )
}

export default Contato
