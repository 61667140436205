import styled from 'styled-components'

import { isMobile } from 'react-device-detect'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: linear-gradient(to bottom, #3703a6, #3703a6, #551e93);

  .box_textos {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .box_texto {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 44px;
  }

  .textCenter {
    font-family: 'RobotoCondensedBold';
    font-size: ${isMobile ? '35px' : '70px'};
    color: #fff;
    flex: 1;
    white-space: nowrap;
    padding-right: 16px;
  }

  .textOpacity {
    font-family: 'RobotoCondensedBold';
    font-size: ${isMobile ? '35px' : '70px'};
    color: #fff;
    opacity: 0.2;
    flex: 0.5;
    white-space: nowrap;
    padding-right: 16px;
  }
`

export const Content = styled.div`
  max-width: ${isMobile ? '100%' : '1170px'};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? 'center' : 'flex-start'};
  flex-direction: column;
  h1 {
    font-family: 'RobotoCondensedBold';
    font-size: ${isMobile ? '70px' : '140px'};
    color: #fff;
    width: 100%;
    padding-left: ${isMobile ? '16px' : '15%'};
    margin-top: ${isMobile ? '20px' : '120px'};
  }
  .anim {
    width: 100%;
  }
  .tituloPessoas1 {
    margin: 0;
    opacity: 0.4;
    margin-top: ${isMobile ? '-60px' : '-100px'};
  }

  .tituloPessoas2 {
    margin: 0;
    opacity: 0.2;
    margin-top: ${isMobile ? '-52px' : '-92px'};
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 515px);
    margin-top: ${isMobile ? '-25px' : '-125px'};
    position: relative;
    margin-bottom: ${isMobile ? '25px' : '0'};
    @media (max-width: 768px) {
      grid-template-rows: repeat(3, 256px);
      img {
        width: 100%;
      }
    }
  }

  .center {
    align-items: center;
  }

  .item {
    margin: 44px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    @media (max-width: 768px) {
      margin: 20px;
      height: 200px;
    }
  }

  .item p {
    font-family: 'RobotoCondensedBold';
    font-size: ${isMobile ? '16px' : '22px'};
    color: #fff;
  }

  .item:hover img {
    opacity: 0.5;
  }

  .item a {
    position: absolute;
    opacity: 0;
  }

  .item:hover a {
    position: absolute;
    bottom: 100px;
    opacity: 1;
    @media (max-width: 768px) {
      bottom: 60px;
    }
  }
`
