import React from 'react'
import { CgInstagram, CgFacebook } from 'react-icons/cg'

import { Link, useLocation } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { Container, Menu } from './styles'

import MenuMobile from '../../components/Menu/mobile'

const ItensMenu: React.FC = () => {
  const location = useLocation()

  const screen = location?.state?.screen
  return (
    <Container>
      <MenuMobile pageName="itensMenu" />
      <Menu>
        <div className="link">
          <ul>
            <li>
              {' '}
              <Link to="/sobre" className={screen === 'sobre' ? 'active' : ''}>
                sobre
              </Link>
            </li>
            <li>
              {' '}
              <Link
                to="/oquefazemos"
                className={screen === 'oquefazemos' ? 'active' : ''}
              >
                o que fazemos
              </Link>
            </li>
            <li>
              {' '}
              <Link
                to="/novosnegocios"
                className={screen === 'novosnegocios' ? 'active' : ''}
              >
                novos negócios
              </Link>
            </li>
            <li>
              {' '}
              <Link to="/cases" className={screen === 'cases' ? 'active' : ''}>
                cases
              </Link>
            </li>
            <li className="hasSubmenu">
              {' '}
              <p>clientes</p>
            </li>
            {isMobile && (
              <li className="li_submenu">
                <ul className="submenu">
                  <li>
                    <Link
                      to="/pessoas"
                      className={screen === 'pessoas' ? 'active' : ''}
                    >
                      pessoas
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/marcas"
                      className={screen === 'marcas' ? 'active' : ''}
                    >
                      marcas
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      to="/wellness"
                      className={screen === 'wellness' ? 'active' : ''}
                    >
                      wellness
                    </Link>
                  </li> */}
                </ul>
              </li>
            )}
            <li>
              {' '}
              <Link
                to="/contato"
                className={screen === 'contato' ? 'active' : ''}
              >
                contato
              </Link>
            </li>
          </ul>
          {!isMobile && (
            <ul className="submenu">
              <li>
                <Link
                  to="/pessoas"
                  className={screen === 'pessoas' ? 'active' : ''}
                >
                  pessoas
                </Link>
              </li>
              <li>
                <Link
                  to="/marcas"
                  className={screen === 'marcas' ? 'active' : ''}
                >
                  marcas
                </Link>
              </li>
              {/* <li>
                <Link
                  to="/wellness"
                  className={screen === 'wellness' ? 'active' : ''}
                >
                  wellness
                </Link>
              </li> */}
            </ul>
          )}
        </div>
        <div className="network_social_menu">
          <a
            href="https://www.instagram.com/evvacomunicacao/"
            target="_blank"
            rel="noreferrer"
            className="instagram"
          >
            <CgInstagram size={32} color="#f2ae2e" />
          </a>
          <a
            href="https://www.facebook.com/evvacomunicacao/"
            target="_blank"
            rel="noreferrer"
          >
            <CgFacebook size={32} color="#f2ae2e" />
          </a>
        </div>
      </Menu>
    </Container>
  )
}

export default ItensMenu
