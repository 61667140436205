import React from 'react'

import { CgInstagram } from 'react-icons/cg'
import { Container, Content } from './styles'

import MenuMobile from '../../components/Menu/mobile'
import Footer from '../../components/Footer'

import image2 from '../../assets/imagens/marcas/marcas_02.png'
import image9 from '../../assets/imagens/marcas/marcas_09.png'
import image18 from '../../assets/imagens/marcas/marcas_18.png'
import image19 from '../../assets/imagens/marcas/marcas_19.png'
import image20 from '../../assets/imagens/marcas/marcas_20.png'
import image24 from '../../assets/imagens/marcas/marcas_24.png'
import image27 from '../../assets/imagens/marcas/marcas_27.png'
import image28 from '../../assets/imagens/marcas/marcas_28.png'
import image29 from '../../assets/imagens/marcas/marcas_29.png'
import image30 from '../../assets/imagens/marcas/marcas_30.png'
import image31 from '../../assets/imagens/marcas/marcas_31.png'
import image32 from '../../assets/imagens/marcas/marcas_32.png'
import image33 from '../../assets/imagens/marcas/marcas_33.png'
import image34 from '../../assets/imagens/marcas/marcas_34.png'
import image35 from '../../assets/imagens/marcas/marcas_35.png'
import image36 from '../../assets/imagens/marcas/marcas_36.png'

const Marcas: React.FC = () => {
  return (
    <Container>
      <MenuMobile pageName="marcas" />
      <Content>
        <h1>marcas</h1>
        <h1 className="tituloPessoas1">marcas</h1>
        <h1 className="tituloPessoas2">marcas</h1>

        <section className="grid center">
          <div className="item">
            <img src={image30} alt="cuidado e informação" />
            <a
              href="https://www.instagram.com/calcadospiccadilly/"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
          <div className="item">
            <img src={image19} alt="cuidado e informação" />
            <a
              href="https://www.instagram.com/bemboladobrasil"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
          <div className="item">
            <img src={image20} alt="cuidado e informação" />
            <a
              href="https://www.instagram.com/perfumariasumire"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
          <div className="item">
            <img src={image18} alt="cuidado e informação" />
            <a
              href="https://www.instagram.com/jahdoacai"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
          <div className="item">
            <img src={image29} alt="cuidado e informação" />
            <a
              href="https://www.instagram.com/nutrata/"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
          <div className="item">
            <img src={image2} alt="cuidado e informação" />
            <a
              href="https://www.instagram.com/amata.sp/"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
          <div className="item">
            <img src={image24} alt="cuidado e informação" />
            <a
              href="https://www.instagram.com/institutosermais/"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
          <div className="item">
            <img src={image9} alt="cuidado e informação" />
            <a
              href="https://www.instagram.com/featmusicbr/"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
          <div className="item">
            <img src={image28} alt="cuidado e informação" />
            <a
              href="https://instagram.com/wolfwolf.works"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
          <div className="item">
            <img src={image27} alt="cuidado e informação" />
            <a
              href="https://instagram.com/cely.br"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
          <div className="item">
            <img src={image31} alt="cuidado e informação" />
            <a
              href="https://www.instagram.com/we.bev"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
          <div className="item">
            <img src={image32} alt="cuidado e informação" />
            <a
              href="https://www.instagram.com/vivapuroverde"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
          <div className="item">
            <img src={image33} alt="cuidado e informação" />
            <a
              href="https://www.instagram.com/highkombucha"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
          <div className="item">
            <img src={image34} alt="cuidado e informação" />
            <a
              href="https://www.instagram.com/clos_winebar"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
          <div className="item">
            <img src={image35} alt="cuidado e informação" />
            <a
              href="https://www.instagram.com/tarantinocervejaria"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
          <div className="item">
            <img src={image36} alt="cuidado e informação" />
            <a
              href="https://www.instagram.com/amobelezabr"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
        </section>
      </Content>

      <Footer />
    </Container>
  )
}

export default Marcas
