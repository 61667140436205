import React from 'react'

import { CgInstagram } from 'react-icons/cg'
import { Container, Content } from './styles'

import MenuMobile from '../../components/Menu/mobile'
import Footer from '../../components/Footer'

import image2 from '../../assets/imagens/wellness/wellness_02.png'
import image7 from '../../assets/imagens/wellness/wellness_07.png'
import image8 from '../../assets/imagens/wellness/wellness_08.png'
import image9 from '../../assets/imagens/wellness/wellness_09.png'
import image10 from '../../assets/imagens/wellness/wellness_10.png'
import image11 from '../../assets/imagens/wellness/wellness_11.png'
import image12 from '../../assets/imagens/wellness/wellness_12.png'

const Wellness: React.FC = () => {
  return (
    <Container>
      <MenuMobile pageName="wellness" />
      <Content>
        <h1>wellness</h1>
        <h1 className="tituloPessoas1">wellness</h1>
        <h1 className="tituloPessoas2">wellness</h1>

        <section className="grid center">
          <div className="item">
            <img src={image2} alt="cuidado e informação" />
            <p>KARINA SOEIRO</p>
            <a
              href="https://www.instagram.com/ka.soeiro/"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
          <div className="item">
            <img src={image12} alt="cuidado e informação" />
            <p>INPI</p>
            <a
              href="https://www.instagram.com/inpi.instituto/"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
          <div className="item">
            <img src={image10} alt="cuidado e informação" />
            <p>ARIÀDNY ABBUD</p>
            <a
              href="https://www.instagram.com/psi.ariadnyabbud/"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
          <div className="item">
            <img src={image11} alt="cuidado e informação" />
            <p>NATHALIA HERINGER</p>
            <a
              href="https://www.instagram.com/nathalia.heringer/"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
          <div className="item">
            <img src={image7} alt="cuidado e informação" />
            <p>KELLY CURCIALEIRO</p>
            <a
              href="https://www.instagram.com/kellycurcialeiro/"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
          <div className="item">
            <img src={image8} alt="cuidado e informação" />
            <p>RENATA BARCELLI</p>
            <a
              href="https://www.instagram.com/rebarcelli/"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
          <div className="item">
            <img src={image9} alt="cuidado e informação" />
            <p>EDIANE RIBEIRO</p>
            <a
              href="https://www.instagram.com/edianeoribeiro/"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
        </section>
      </Content>

      <Footer />
    </Container>
  )
}

export default Wellness
