import styled from 'styled-components'
import { shade } from 'polished'
import { isMobile } from 'react-device-detect'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-color: #3703a6;
  @media (max-width: 768px) {
    margin-top: 24px;
  }
  .menu_top {
    width: 100%;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .one,
  .two,
  .three {
    background-color: #f27289;
    height: 2px;
    width: 100%;
    margin: 12px auto;
    transition-duration: 0.5s;
  }

  .menu ul li a {
    color: #f27289;
  }
  .menu.on .one,
  .menu.on .two,
  .menu.on .three {
    background-color: #3703a6;
  }
  .menu-toggle.on .one {
    transform: rotate(45deg) translate(7px, 7px);
  }

  .menu-toggle.on .two {
    transform: rotate(-45deg) translate(3px, -4px);
  }

  .menu-toggle.on .three {
    transform: rotate(-45deg) translate(8px, -9px);
  }

  .menu-toggle {
    width: 50px;
    height: 50px;
    border: none;
    background: none;
    position: fixed;
    right: 10%;
    z-index: 9;
    @media (max-width: 768px) {
      width: 45px;
      height: 45px;
      right: 6%;
    }
  }
  .ReactModal__Content,
  .ReactModal__Content--after-open {
    background-color: #3703a6 !important;
  }
`
export const Menu = styled.div`
  width: 100%;
  .link {
    width: 100%;
    /* height: 100vh; */
    background-color: #3703a6;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    /* padding-bottom: 200px; */
  }
  ul {
    display: flex;
    flex-direction: column;
    outline: none;
  }

  ul li {
    list-style: none;
    width: 100%;
    padding: 10px 0;
    text-align: center;
    outline: none;
  }
  ul li a,
  ul li p {
    color: #fff;
    font-family: 'RobotoCondensedBold';
    font-size: ${isMobile ? '25px' : '40px'};
    padding: 0;
    text-decoration: none;
    text-align: center;
    border-bottom: solid 1px #3703a6;
  }
  @media (max-width: 768px) {
    ul li p {
      text-align: center;
    }
  }
  li.active,
  li.actual {
    background: ${shade(0.2, '#ff6000')};
    color: #fff;
  }

  li a:hover,
  li a.active {
    color: #f2ae2e;
    /* border-bottom: solid 1px #f2ae2e; */
  }

  li.hasSubmenu {
    @media (max-width: 768px) {
      padding: 10px 0 0 0;
    }
  }
  li.hasSubmenu a,
  li.hasSubmenu p {
    color: #fff;
    border-bottom: 0;
  }

  li.hasSubmenu a:hover,
  li.hasSubmenu a:active {
    color: #fff;
    border-bottom: 0;
  }
  .li_submenu {
    padding: 0 0 10px 0;
  }
  ul.submenu {
    display: flex;
    flex-direction: row;
    padding: 0 0 0 20px;

    @media (max-width: 768px) {
      padding: 0;
    }
  }

  ul.submenu li {
    padding: 0 10px;
    border-bottom: 0;
  }

  ul.submenu li a {
    border-bottom: solid 1px #3703a6;
    font-size: ${isMobile ? '20px' : '25px'};
  }

  ul.submenu li a:hover,
  ul.submenu li a.active {
    color: #f2ae2e;
    /* border-bottom: solid 1px #f2ae2e; */
  }

  .network_social_menu {
    margin: 70px 0 70px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .instagram {
    margin-right: 20px;
  }
`
