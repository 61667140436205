import styled from 'styled-components'

import { isMobile } from 'react-device-detect'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: linear-gradient(to bottom, #3703a6, #3703a6, #551e93);

  .box_textos {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
  }

  .box_texto {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 0;
    width: 100%;
    padding: 70px 0;
    @media (max-width: 1400px) {
      padding: 50px 0;
    }
    @media (max-width: 768px) {
      padding: 20px 0;
    }
  }

  .box_texto.last {
    padding: 70px 0 0 0;
    @media (max-width: 1400px) {
      padding: 50px 0 0 0;
    }
    @media (max-width: 768px) {
      padding: 20px 0 0 0;
    }
  }

  .anim {
    display: flex;
    flex-direction: row;
  }

  .textCenter {
    font-family: 'RobotoCondensedRegular';
    font-size: 100px;
    color: #fff;
    flex: 1;
    white-space: nowrap;
    padding-right: 16px;
    @media (max-width: 1400px) {
      font-size: 80px;
    }
    @media (max-width: 1125px) {
      font-size: 60px;
    }
    @media (max-width: 768px) {
      font-size: 25px;
    }
  }

  .textOpacity {
    font-family: 'RobotoCondensedRegular';
    font-size: 100px;
    color: #fff;
    opacity: 0.2;
    flex: 0.5;
    white-space: nowrap;
    padding-right: 16px;
    @media (max-width: 1400px) {
      font-size: 80px;
    }
    @media (max-width: 1125px) {
      font-size: 60px;
    }
    @media (max-width: 768px) {
      font-size: 25px;
    }
  }
  @media (max-width: 768px) {
    font-size: 25px;
  }
`

export const Content = styled.div`
  max-width: ${isMobile ? '100%' : '1170px'};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? 'center' : 'flex-start'};
  flex-direction: column;
  h1 {
    font-family: 'RobotoCondensedBold';
    font-size: ${isMobile ? '70px' : '140px'};
    color: #fff;
    width: 100%;
    padding-left: 15%;
    margin-top: ${isMobile ? '20px' : '120px'};
    margin-bottom: 50px;
    line-height: 80px;
    @media (max-width: 768px) {
      margin: 20px 0;
      padding: 0 16px;
    }
  }
`
