import React from 'react'

import Modal from 'react-modal'
import { CgInstagram, CgFacebook } from 'react-icons/cg'

import { Link } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { Container, Menu } from './styles'
import logo from '../../assets/imagens/logo.svg'

interface IProps {
  modalIsOpen: boolean
  closeModal: () => void
  screen?: string
}

const ModalMenu: React.FC<IProps> = ({
  modalIsOpen,
  screen,
  closeModal,
}: IProps) => {
  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: '#3703a6',
          },
          content: {
            position: 'absolute',
            top: '40px',
            left: '40px',
            right: '40px',
            bottom: '40px',
            border: 'none',
            backgroundColor: '#3703a6 !important',
            overflow: 'hidden',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '0',
          },
        }}
      >
        <div className={`modal ${modalIsOpen ? 'open' : 'closed'}`}>
          <Container>
            <div className="menu_top">
              <Link to="/">
                {isMobile && (
                  <img src={logo} alt="logo" width={150} height={75} />
                )}
                {!isMobile && (
                  <img src={logo} alt="logo" width={200} height={100} />
                )}
              </Link>
              <button
                type="button"
                className="menu-toggle on"
                onClick={closeModal}
              >
                <div className="one" />
                <div className="two" />
              </button>
            </div>

            <Menu>
              <div className="link">
                <ul>
                  <li>
                    {' '}
                    <Link
                      to="/sobre"
                      className={screen === 'sobre' ? 'active' : ''}
                    >
                      sobre
                    </Link>
                  </li>
                  <li>
                    {' '}
                    <Link
                      to="/oquefazemos"
                      className={screen === 'oquefazemos' ? 'active' : ''}
                    >
                      o que fazemos
                    </Link>
                  </li>
                  <li>
                    {' '}
                    <Link
                      to="/novosnegocios"
                      className={screen === 'novosnegocios' ? 'active' : ''}
                    >
                      novos negócios
                    </Link>
                  </li>
                  <li>
                    {' '}
                    <Link
                      to="/cases"
                      className={screen === 'cases' ? 'active' : ''}
                    >
                      cases
                    </Link>
                  </li>
                  <li className="hasSubmenu">
                    {' '}
                    <p>clientes</p>
                    {!isMobile && (
                      <ul className="submenu">
                        <li>
                          <Link
                            to="/pessoas"
                            className={screen === 'pessoas' ? 'active' : ''}
                          >
                            pessoas
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/marcas"
                            className={screen === 'marcas' ? 'active' : ''}
                          >
                            marcas
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/wellness"
                            className={screen === 'wellness' ? 'active' : ''}
                          >
                            wellness
                          </Link>
                        </li>
                      </ul>
                    )}
                  </li>
                  {isMobile && (
                    <li className="li_submenu">
                      <ul className="submenu">
                        <li>
                          <Link
                            to="/pessoas"
                            className={screen === 'pessoas' ? 'active' : ''}
                          >
                            pessoas
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/marcas"
                            className={screen === 'marcas' ? 'active' : ''}
                          >
                            marcas
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/wellness"
                            className={screen === 'wellness' ? 'active' : ''}
                          >
                            wellness
                          </Link>
                        </li>
                      </ul>
                    </li>
                  )}
                  <li>
                    {' '}
                    <Link
                      to="/contato"
                      className={screen === 'contato' ? 'active' : ''}
                    >
                      contato
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="network_social_menu">
                <a
                  href="https://www.instagram.com/evvacomunicacao/"
                  target="_blank"
                  rel="noreferrer"
                  className="instagram"
                >
                  <CgInstagram size={32} color="#f2ae2e" />
                </a>
                <a
                  href="https://www.facebook.com/evvacomunicacao/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <CgFacebook size={32} color="#f2ae2e" />
                </a>
              </div>
            </Menu>
          </Container>
        </div>
      </Modal>
    </div>
  )
}
ModalMenu.defaultProps = {
  screen: '',
}
export default ModalMenu
