import styled from 'styled-components'
import { shade } from 'polished'
import { isMobile } from 'react-device-detect'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-color: #fff;
`
export const Menu = styled.div`
  width: 100%;
  .link {
    width: 100%;
    /* height: 100vh; */
    background-color: #fff;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    /* padding-bottom: 200px; */
  }
  ul {
    display: flex;
    flex-direction: column;
    outline: none;
  }

  ul li {
    list-style: none;
    width: 100%;
    padding: 10px 0;
    text-align: ${isMobile ? 'center' : 'right'};
    outline: none;
  }
  ul li a,
  ul li p {
    color: #3703a6;
    font-family: 'RobotoCondensedRegular';
    font-size: ${isMobile ? '25px' : '40px'};
    padding: 0;
    text-decoration: none;
    text-align: end;
    border-bottom: solid 1px #3703a6;
  }
  @media (max-width: 768px) {
    ul li p {
      text-align: center;
    }
  }
  li.active,
  li.actual {
    background: ${shade(0.2, '#ff6000')};
    color: #fff;
  }

  li a:hover,
  li a.active {
    color: #f2ae2e;
    border-bottom: solid 1px #f2ae2e;
  }

  li.hasSubmenu {
    @media (max-width: 768px) {
      padding: 10px 0 0 0;
    }
  }
  li.hasSubmenu a,
  li.hasSubmenu p {
    color: #3703a6;
    border-bottom: 0;
  }

  li.hasSubmenu a:hover,
  li.hasSubmenu a:active {
    color: #3703a6;
    border-bottom: 0;
  }
  .li_submenu {
    padding: 0 0 10px 0;
  }
  ul.submenu {
    display: flex;
    flex-direction: row;
    margin-top: ${isMobile ? '0' : '220px'};
    padding: 0 0 0 20px;

    @media (max-width: 768px) {
      padding: 0;
    }
  }

  ul.submenu li {
    padding: 0 10px;
    border-bottom: 0;
  }

  ul.submenu li a {
    border-bottom: solid 1px #3703a6;
    font-size: ${isMobile ? '20px' : '25px'};
  }

  ul.submenu li a:hover,
  ul.submenu li a.active {
    color: #f2ae2e;
    border-bottom: solid 1px #f2ae2e;
  }

  .network_social_menu {
    margin: 70px 0 70px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .instagram {
    margin-right: 20px;
  }
`
