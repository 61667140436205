import React from 'react'

import AOS from 'aos'
import 'aos/dist/aos.css'
import { Container, Content } from './styles'
import MenuMobile from '../../components/Menu/mobile'
import Footer from '../../components/Footer'

AOS.init()

const OQueFazemos: React.FC = () => {
  return (
    <Container>
      <MenuMobile pageName="oquefazemos" />
      <Content>
        <h1>o que fazemos</h1>
      </Content>
      <div className="box_textos">
        <div
          data-aos="slide-up"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
          data-aos-once="true"
        >
          <div className="box_texto" id="assesoria">
            <p className="textOpacity anim">assessoria de imprensa</p>
            <p className="textCenter anim">assessoria de imprensa</p>
            <p className="textOpacity anim">assessoria de imprensa</p>
          </div>
        </div>

        <div
          data-aos="slide-up"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
          data-aos-once="true"
        >
          <div className="box_texto" id="relacoes">
            <p className="textOpacity anim">relações públicas - RP 2.0</p>
            <p className="textCenter anim">relações públicas - RP 2.0 </p>
            <p className="textOpacity anim">relações públicas - RP 2.0</p>
          </div>
        </div>

        <div
          data-aos="slide-up"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
          data-aos-once="true"
        >
          <div className="box_texto" id="media">
            <p className="textOpacity anim">media training e consultoria</p>
            <p className="textCenter anim">media training e consultoria</p>
            <p className="textOpacity anim">media training e consultoria</p>
          </div>
        </div>
        <div
          data-aos="slide-up"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
          data-aos-once="true"
        >
          <div className="box_texto" id="conteudo">
            <p className="textOpacity anim">conteúdo digital</p>
            <p className="textCenter anim">conteúdo digital</p>
            <p className="textOpacity anim">conteúdo digital</p>
          </div>
        </div>
        <div
          data-aos="slide-up"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
          data-aos-once="true"
        >
          <div className="box_texto" id="marketing">
            <p className="textOpacity anim">marketing digital</p>
            <p className="textCenter anim">marketing digital</p>
            <p className="textOpacity anim">marketing digital</p>
          </div>
        </div>
        <div
          data-aos="slide-up"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
          data-aos-once="true"
        >
          <div className="box_texto" id="prevencao">
            <p className="textOpacity anim">
              prevenção e gerenciamento de crise
            </p>
            <p className="textCenter anim">
              prevenção e gerenciamento de crise
            </p>
            <p className="textOpacity anim">
              prevenção e gerenciamento de crise
            </p>
          </div>
        </div>

        <div
          data-aos="slide-up"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
          data-aos-once="true"
        >
          <div className="box_texto last" id="producao">
            <p className="textOpacity anim">produção e execução de eventos</p>
            <p className="textCenter anim">produção e execução de eventos</p>
            <p className="textOpacity anim">produção e execução de eventos</p>
          </div>
        </div>
      </div>

      <Footer />
    </Container>
  )
}

export default OQueFazemos
