import styled from 'styled-components'
import { isMobile } from 'react-device-detect'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: 'row';
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  margin: 250px 0 90px;
  @media (max-width: 768px) {
    margin: 90px 0 90px;
    padding: 0 15px;
  }
`
export const Content = styled.div`
  border: 0;
  padding: 16px;
  width: 100%;
  max-width: ${isMobile ? '100%' : '1170px'};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      margin: 20px 0;
    }
  }
  div.column {
    flex-direction: column;
  }

  p {
    font-family: 'RobotoCondensedBold';
    font-size: 18px;
    color: #f2ae2e;
    width: 100%;
    text-align: center;
  }

  p.first {
    text-align: left;
  }
  p.first.color {
    color: #fff;
  }

  p.last {
    text-align: ${isMobile ? 'center' : 'right'};
    @media (max-width: 768px) {
      line-height: 35px;
    }
  }

  p.middle {
    font-size: 22px;
    color: #fff;
  }

  div.last {
    justify-content: flex-end;
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  }

  .instagram {
    margin-right: 20px;
  }

  @media (max-width: 768px) {
    font-size: 13px;
    flex-direction: column-reverse;
  }
`
