import React, { useCallback, useState } from 'react'

import { CgMathPlus } from 'react-icons/cg'
import { useHistory } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { Container, Content, Perfil, Descricao } from './styles'

import MenuMobile from '../../components/Menu/mobile'
import Footer from '../../components/Footer'

import jsonCases from '../../assets/json/cases.json'

import ICases from '../../interfaces/entities/Cases'

const Cases: React.FC = () => {
  const [numberCasesShow, setNumberCasesShow] = useState(3)
  const history = useHistory()

  const casesTotal: ICases[] = jsonCases.slice(0, numberCasesShow)

  const addMoreCases = useCallback(() => {
    let numberTotal = numberCasesShow + 1

    if (numberTotal > jsonCases.length) numberTotal = jsonCases.length

    setNumberCasesShow(numberTotal)
  }, [numberCasesShow])

  return (
    <Container>
      <MenuMobile pageName="cases" />
      <Content>
        <h1>cases</h1>
        <h1 className="tituloPessoas1">cases</h1>
        <h1 className="tituloPessoas2">cases</h1>

        <div className="perfil">
          <Perfil>
            <Content>
              {casesTotal?.map((caseDetails: ICases, index: number) => (
                <Descricao
                  key={caseDetails.title}
                  isLast={casesTotal.length - 1 === index}
                >
                  <div className="caseImagem">
                    <img
                      src={`/imagens/cases/${caseDetails?.imagens[0]}`}
                      alt="cuidado e informação"
                    />
                  </div>
                  <div className="box">
                    <p className="nome">{caseDetails?.title}</p>

                    <div className="cargo">
                      <p className="funcao">{caseDetails?.subTitle}</p>
                    </div>

                    <button
                      onClick={() => history.push('/caseDetails', { index })}
                      type="button"
                    >
                      <CgMathPlus size={isMobile ? 44 : 44} color="#f2ae2e" />
                    </button>
                  </div>
                </Descricao>
              ))}
            </Content>
          </Perfil>
        </div>

        {numberCasesShow < jsonCases.length && (
          <div className="boxButtonMore">
            <button type="button" onClick={addMoreCases}>
              <span>mais</span>
              <span>cases</span>
            </button>
          </div>
        )}

        <Footer />
      </Content>
    </Container>
  )
}

export default Cases
