import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  margin-top: 160px;
  margin-bottom: 40px;
  padding: 0 15px;
  position: relative;

  h1 {
    font-family: 'RobotoCondensedBold';
    font-size: 55px;
    line-height: 70px;

    color: #fff;
    z-index: 2;
    position: relative;
    opacity: 0.5;
    text-align: right;

    padding: 0 15px;
  }
  h1:hover {
    opacity: 1;
  }
  h1.active {
    opacity: 1;
  }

  #people:hover + #marca {
    display: none !important;
    background: #ccc;
  }

  #marc:hover + #marca {
    display: none !important;
    background: #ccc;
  }
  #well:hover + #marca {
    display: none !important;
    background: #ccc;
  }

  img {
    width: 50%;
    margin-top: -113px;
    margin-left: 45%;
  }

  .hide {
    display: none;
  }
`

export const Box = styled.div`
  position: absolute;
`

export const Button = styled.button`
  background: none;
  border: none;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
