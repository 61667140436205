import styled from 'styled-components'

import { isMobile } from 'react-device-detect'

import capa from '../../assets/videos/capa.jpg'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: linear-gradient(to bottom, #3703a6, #3703a6, #551e93);

  .box_textos {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
  }

  .box_texto {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 0;
    width: 100%;
    padding: 70px 0;
    overflow: hidden;
    @media (max-width: 768px) {
      padding: 20px 0;
    }
  }
  .box_texto.last {
    padding: 70px 0 0 0;
    @media (max-width: 768px) {
      padding: 20px 0;
    }
  }

  .textCenter {
    font-family: 'RobotoCondensedRegular';
    font-size: ${isMobile ? '25px' : '100px'};
    color: #fff;
    flex: 1;
    white-space: nowrap;
    padding-right: 16px;
  }

  .textOpacity {
    font-family: 'RobotoCondensedRegular';
    font-size: ${isMobile ? '25px' : '100px'};
    color: #fff;
    opacity: 0.2;
    flex: 0.5;
    white-space: nowrap;
    padding-right: 16px;
  }
`

export const Content = styled.div`
  max-width: ${isMobile ? '100%' : '1170px'};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? 'center' : 'flex-start'};
  flex-direction: column;
  .videoBox {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: ${isMobile ? '20px' : '120px'};
    background-image: url(${capa});
    width: 650px;
    height: 650px;
    @media (max-width: 768px) {
      padding: 0;
      width: 100%;
      height: 100%;
      background-image: none;
    }
  }
  h1 {
    font-family: 'RobotoCondensedBold';
    font-size: ${isMobile ? '70px' : '140px'};
    color: #fff;
    width: 100%;
    padding-left: 15%;
    margin-top: ${isMobile ? '20px' : '100px'};
    margin-bottom: ${isMobile ? '0' : '50px'};
    @media (max-width: 768px) {
      padding: 0 16px;
      line-height: 54px;
      margin: 20px 0;
    }
  }
  h1.amarelo {
    color: #f2ae2e;
  }
  .boxTexto {
    max-width: ${isMobile ? '100%' : '1170px'};
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: ${isMobile ? 'center' : 'flex-start'};
    flex-direction: column;
    padding-left: 30%;
    padding-right: 56px;
    @media (max-width: 768px) {
      padding: 0 16px;
    }
  }
  p.novosNegociosText {
    font-family: 'RobotoCondensedRegular';
    font-size: 18px;
    line-height: 30px;
    color: #fff;
    width: 100%;
    margin-top: ${isMobile ? '30px' : '50px'};
    max-width: 600px;
    @media (max-width: 768px) {
      margin-top: 20px;
      padding: 0;
    }
  }
  p.novosNegociosTextAmarelo {
    font-family: 'RobotoCondensedRegular';
    font-size: 22px;
    color: #f2ae2e;
    text-align: left;
    width: 100%;
  }

  .perspective-box {
    display: flex;
    flex-direction: column;
    align-self: center;
    margin: 100px 0 200px;
  }
  .perspective-line {
    font-family: 'RobotoCondensedBold';
    font-size: 44px;
    color: #fff;
    margin-bottom: 10px;
  }
  .perspective-line:nth-child(odd) {
    transform: skew(0deg, -30deg) scaleY(1.337);
    background: #f2ae2e;
  }

  .perspective-line:nth-child(even) {
    transform: skew(60deg, -30deg) scaleY(0.667);
  }

  .perspective-line:nth-child(1) {
    margin-left: -30px;
    padding-left: 10px;
  }

  .perspective-line:nth-child(2) {
    margin-left: 0;
  }
  .perspective-line:nth-child(3) {
    margin-left: 0;
    margin-top: 10px;
    padding-left: 10px;
  }
  .perspective-line:nth-child(4) {
    margin-left: 34px;
  }

  @media (max-width: 768px) {
    .perspective-box {
      margin: 10px 0 70px;
    }
    .perspective-line {
      font-size: 32px;
    }
    .perspective-line:nth-child(1) {
      margin-top: 70px;
      margin-left: 0px;
      padding-left: 10px;
      width: 100%;
    }

    .perspective-line:nth-child(2) {
      margin-left: 10px;
      padding-left: 10px;
      margin-top: 0px;
    }
    .perspective-line:nth-child(3) {
      margin-left: 30px;
      margin-top: 20px;
      padding-left: 5px;
      width: 70%;
    }
    .perspective-line:nth-child(4) {
      margin-left: 0px;
      padding-left: 50px;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(1, 240px);
    position: relative;
    margin-top: ${isMobile ? '25px' : '90px'};
    margin-bottom: ${isMobile ? '25px' : '50px'};
    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 200px);
    }
  }

  .center {
    align-items: center;
  }

  .item {
    margin: 44px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border: solid 2px #f27289;
    border-radius: 50%;
    @media (max-width: 768px) {
      margin: 20px;
    }
  }
  .item.border_dashed {
    border: dashed 2px #f27289;
  }

  .item p {
    font-family: 'RobotoCondensedBold';
    font-size: ${isMobile ? '16px' : '22px'};
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 230px;
    height: 230px;
    text-transform: lowercase;
    @media (max-width: 1230px) {
      width: 150px;
      height: 150px;
      font-size: 16px;
    }
    @media (max-width: 780px) {
      width: 130px;
      height: 130px;
      font-size: 16px;
    }
  }
  .cardVideo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 0;
    width: 100%;
    word-wrap: break-word;
    box-sizing: border-box;
    margin-top: ${isMobile ? '50px' : '100px'};
    padding-left: 30%;
    @media (max-width: 920px) {
      width: 50%;
      padding: 0;
      padding: 0 16px;
      margin: 50px 0 30px;
    }
    @media (max-width: 768px) {
      width: 100%;
      align-items: center;
      padding: 0 16px;
      margin: 50px 0 30px;
    }
  }
  .modal-video-body {
    max-width: 940px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: table;
  }
  .modal-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(55, 3, 166, 0.8);
    z-index: 1000000;
    cursor: pointer;
    opacity: 1;
    animation-timing-function: ease-out;
    animation-duration: 0.3s;
    animation-name: modal-video;
    -webkit-transition: opacity 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out;
    -ms-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
  }
  .modal-video-effect-exit {
    opacity: 0;
  }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    -moz-transform: translate(0, 100px);
    -ms-transform: translate(0, 100px);
    -o-transform: translate(0, 100px);
    transform: translate(0, 100px);
  }
  .modal-video-body {
    max-width: 940px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: table;
  }
  .modal-video-inner {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%;
  }
  .modal-video-movie-wrap {
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.25%;
    background-color: #000;
    animation-timing-function: ease-out;
    animation-duration: 0.3s;
    animation-name: modal-video-inner;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transition: -webkit-transform 0.3s ease-out;
    -moz-transition: -moz-transform 0.3s ease-out;
    -ms-transition: -ms-transform 0.3s ease-out;
    -o-transition: -o-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
  }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .modal-video-close-btn {
    position: absolute;
    z-index: 2;
    top: -35px;
    right: -35px;
    display: inline-block;
    width: 35px;
    height: 35px;
    overflow: hidden;
    border: none;
    background: transparent;
  }
  .modal-video-close-btn:before {
    transform: rotate(45deg);
  }
  .modal-video-close-btn:after {
    transform: rotate(-45deg);
  }
  .modal-video-close-btn:before,
  .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #000;
    border-radius: 5px;
    margin-top: -6px;
  }
  button {
    background: none;
    border: none;
  }
`
