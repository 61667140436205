import React from 'react'
import { isMobile } from 'react-device-detect'
import { CgInstagram, CgFacebook } from 'react-icons/cg'
import { Container, Content } from './styles'

interface IProps {
  isHome?: boolean
}

const Footer: React.FC<IProps> = ({ isHome }: IProps) => {
  const changedColorIcon = isHome && isMobile
  return (
    <Container>
      <Content>
        <div>
          {changedColorIcon && <p className="first color">©evva comunicação</p>}
          {!changedColorIcon && <p className="first">©evva comunicação</p>}
        </div>

        <div>
          <a
            href="https://www.instagram.com/evvacomunicacao/"
            target="_blank"
            rel="noreferrer"
            className="instagram"
          >
            <CgInstagram
              size={32}
              color={changedColorIcon ? '#fff' : '#f2ae2e'}
            />
          </a>
          <a
            href="https://www.facebook.com/evvacomunicacao/"
            target="_blank"
            rel="noreferrer"
          >
            <CgFacebook
              size={32}
              color={changedColorIcon ? '#fff' : '#f2ae2e'}
            />
          </a>
        </div>
        <div className="last">
          {changedColorIcon && (
            <>
              <p className="last color">contato@evvacomunicacao.com</p>
              <p className="last color">+55 11 2774.1387</p>
            </>
          )}
          {!changedColorIcon && (
            <div className="column">
              <p className="last">contato@evvacomunicacao.com</p>
              <p className="last">+55 11 2774.1387</p>
            </div>
          )}
        </div>
      </Content>
    </Container>
  )
}

Footer.defaultProps = {
  isHome: false,
}
export default Footer
