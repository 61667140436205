import styled from 'styled-components'

import { isMobile, isIOS } from 'react-device-detect'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(
    to bottom,
    #3703a6 30%,
    #551e93,
    #f2ae2e,
    #f2ae2e,
    #3703a6,
    #3703a6
  );

  text {
    fill: #f27289;
    transform: translate3d(0, 0, 0);
    width: 100%;
    font-size: 80px;
    height: 100%;
    font-family: 'RobotoCondensedBold';
    @media (max-width: 768px) {
      font-size: 150px;
    }
  }

  textPath {
    transform: translate3d(0, 0, 0);
  }
  .boxButton {
    margin-bottom: ${isMobile ? '80px' : '100px'};
  }

  @media (max-width: 768px) {
    background-image: linear-gradient(
      to bottom,
      #3703a6 25%,
      #551e93,
      #f2ae2e,
      #f2ae2e 57%,
      #3703a6,
      #3703a6,
      #3703a6
    );
  }
`
export const Content = styled.div`
  margin-top: 5px;
  max-width: ${isMobile ? '100%' : '1170px'};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 768px) {
    margin-top: 60px;
  }
`
export const Banner = styled.div`
  max-width: ${isMobile ? '100%' : '1170px'};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? 'center' : 'flex-start'};
  flex-direction: column;
  background-size: cover;
  position: relative;
  min-height: 512px;
  padding: 0 16px;

  .title_home.first {
    margin-top: ${isMobile ? '60px' : '100px'};
  }
  .title_home.last {
    margin-bottom: ${isMobile ? '75px' : '150px'};
  }
  .title_home {
    font-size: ${isMobile ? '45px' : '80px'};
    font-family: 'RobotoCondensedBold';
    color: #fff;

    padding: 0 16px;
    max-width: 1085px;
    line-height: 100px;
    text-align: center;
    @media (max-width: 768px) {
      font-size: 45px;
      line-height: 55px;
      padding: 0 8px;
    }
    @media (max-width: 480px) {
      font-size: 37px;
    }
  }
  .img_right {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 270px;
    @media (max-width: 768px) {
      margin-bottom: 70px;
      img {
        width: 70%;
        height: ${isIOS ? '100%' : '100%'};
      }
    }
  }

  .img_left {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    @media (max-width: 768px) {
      margin-top: 10px;
      img {
        width: 70%;
        height: ${isIOS ? '100%' : '100%'};
      }
    }
  }

  @media (max-width: 768px) {
    max-height: 100%;
    min-height: 0%;
    img {
      width: 70%;
    }
  }
`

export const DescriptionText = styled.p`
  font-size: ${isMobile ? '18px' : '30px'};
  font-family: 'RobotoCondensedRegular';
  color: ${props => (props.color ? props.color : '#fff')};
  margin-top: ${isMobile ? '30px' : '60px'};
  line-height: ${isMobile ? '24px' : '40px'};
  padding-right: 200px;
  @media (max-width: 768px) {
    padding: 0 15px;
  }
`

export const DescriptionText2 = styled.p`
  font-size: ${isMobile ? '18px' : '30px'};
  font-family: 'RobotoCondensedRegular';
  color: ${props => (props.color ? props.color : '#fff')};
  margin-top: ${isMobile ? '80px' : '140px'};
  line-height: ${isMobile ? '18px' : '40px'};
  padding-right: 200px;
  @media (max-width: 768px) {
    padding: 0 15px;
  }
`

export const Button = styled.button`
  margin-top: ${isMobile ? '30px' : '50px'};
  background: none;
  border: none;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  span {
    font-family: 'RobotoCondensedBold';
    color: ${props => props.color} !important;
  }
  div {
    margin-left: 5px;
    padding: 0 10px;
    border: solid 1px ${props => props.color};
    border-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const ButtonNav = styled.button`
  background: none;
  border: none;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const Title = styled.p`
  margin: 50px 0 50px;
  color: #f27289;
  font-size: ${isMobile ? '40px' : '50px'};
  font-family: 'RobotoCondensedBold';
  @media (max-width: 768px) {
    margin-top: 100px;
    margin-bottom: 40px;
  }
`
