import React from 'react'
import { useHistory } from 'react-router-dom'
import { Container, Button } from './styles'

const Estrategia: React.FC = () => {
  const history = useHistory()
  return (
    <Container>
      <Button type="button" onClick={() => history.push('/oquefazemos')}>
        <p className="text_estrategia">o que fazemos</p>
      </Button>
      <Button type="button" onClick={() => history.push('/cases')}>
        <p className="text_estrategia">cases</p>
      </Button>
    </Container>
  )
}

export default Estrategia
