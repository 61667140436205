import React, { useState } from 'react'
import ModalVideo from 'react-modal-video'

import AOS from 'aos'
import 'aos/dist/aos.css'

import { isMobile } from 'react-device-detect'

import ReactPlayer from 'react-player'
import { Container, Content } from './styles'

import MenuMobile from '../../components/Menu/mobile'
import Footer from '../../components/Footer'
import videoEvva from '../../assets/videos/video.mp4'
import capa from '../../assets/videos/capa.jpg'

AOS.init()
const NovosNegocios: React.FC = () => {
  // const [viewVideo, setViewVideo] = useState(false)
  const [visibleModal, setVisibleModal] = useState(false)

  // const [isOpenedModal, setIsOpenedModal] = useState(false)

  return (
    <Container>
      <MenuMobile pageName="novosnegocios" />
      <Content>
        <h1>novos negócios</h1>
        <div className="boxTexto">
          <p className="novosNegociosText">
            Muito mais que uma agência de assessoria de imprensa e PR, somos
            especialistas em comunicação 360º, com DNA inquietante e olhar
            atento às tendências de mercado e acontecimentos do mundo.
            Desenvolvemos soluções criativas para atender as necessidades de
            nossos clientes e executar projetos em todos os espaços nos quais
            eles precisam estar.
          </p>
          <p className="novosNegociosText">
            Expandimos ainda mais as fronteiras do PR e da assessoria de
            imprensa para o universo digital, mudamos a maneira como as pessoas
            enxergam o online e promovemos a conexão entre desejo (do cliente),
            realidade (do projeto) e troca verdadeira e social, que resultam em
            marcas e pessoas com propósito e credibilidade.
          </p>
        </div>

        {!visibleModal && (
          <div className="cardVideo">
            <button
              type="button"
              onClick={() => {
                setVisibleModal(true)
              }}
            >
              <img
                src={capa}
                alt="capa"
                width={isMobile ? '100%' : '600'}
                height={isMobile ? '100%' : '600'}
              />
            </button>
          </div>
        )}
        {!isMobile && (
          <ModalVideo
            className="modal-video-body"
            url={videoEvva}
            channel="custom"
            width="650px"
            height="650px"
            isOpen={visibleModal}
            onClose={() => setVisibleModal(false)}
          />
        )}
        {isMobile && visibleModal && (
          <div className="cardVideo">
            <ReactPlayer
              url={videoEvva}
              controls
              width="100%"
              height="100%"
              playing
            />
          </div>
        )}
        <h1 className="amarelo">somos</h1>
        <section className="grid center">
          <div className="item border_dashed">
            <p>DESENVOLVIMENTO</p>
          </div>
          <div className="item border_dashed">
            <p>PLANEJAMENTO</p>
          </div>
          <div className="item">
            <p>EXECUÇÃO</p>
          </div>
          <div className="item">
            <p>GERENCIAMENTO</p>
          </div>
        </section>
      </Content>
      <div className="box_textos">
        <div
          data-aos="slide-up"
          data-aos-duration="500"
          data-aos-delay="100"
          data-aos-once="true"
        >
          <div className="box_texto">
            <p className="textOpacity">fazemos</p>
            <p className="textOpacity">fazemos</p>
            <p className="textOpacity">fazemos</p>
            <p className="textCenter">fazemos</p>
            <p className="textOpacity">fazemos</p>
            <p className="textOpacity">fazemos</p>
            <p className="textOpacity">fazemos</p>
          </div>
        </div>

        <div
          data-aos="slide-up"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
          data-aos-once="true"
        >
          <div className="box_texto">
            <p className="textOpacity">site</p>
            <p className="textOpacity">site</p>
            <p className="textOpacity">site</p>
            <p className="textOpacity">site</p>
            <p className="textOpacity">site</p>
            <p className="textOpacity">site</p>
            <p className="textOpacity">site</p>
            <p className="textOpacity">site</p>
            <p className="textCenter">site</p>
            <p className="textOpacity">site</p>
            <p className="textOpacity">site</p>
            <p className="textOpacity">site</p>
            <p className="textOpacity">site</p>
            <p className="textOpacity">site</p>
            <p className="textOpacity">site</p>
            <p className="textOpacity">site</p>
            <p className="textOpacity">site</p>
          </div>
        </div>
        <div
          data-aos="slide-up"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
          data-aos-once="true"
        >
          <div className="box_texto">
            <p className="textOpacity">ecommerce</p>
            <p className="textOpacity">ecommerce</p>
            <p className="textOpacity">ecommerce</p>
            <p className="textCenter">ecommerce</p>
            <p className="textOpacity">ecommerce</p>
            <p className="textOpacity">ecommerce</p>
            <p className="textOpacity">ecommerce</p>
          </div>
        </div>

        <div
          data-aos="slide-up"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
          data-aos-once="true"
        >
          <div className="box_texto">
            <p className="textOpacity">aplicativo</p>
            <p className="textOpacity">aplicativo</p>
            <p className="textOpacity">aplicativo</p>
            <p className="textCenter">aplicativo</p>
            <p className="textOpacity">aplicativo</p>
            <p className="textOpacity">aplicativo</p>
            <p className="textOpacity">aplicativo</p>
          </div>
        </div>

        <div
          data-aos="slide-up"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
          data-aos-once="true"
        >
          <div className="box_texto">
            <p className="textOpacity">identidade visual</p>
            <p className="textOpacity">identidade visual</p>
            <p className="textCenter">identidade visual</p>
            <p className="textOpacity">identidade visual</p>
            <p className="textOpacity">identidade visual</p>
          </div>
        </div>

        <div
          data-aos="slide-up"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
          data-aos-once="true"
        >
          <div className="box_texto">
            <p className="textOpacity">conteúdo digital</p>
            <p className="textOpacity">conteúdo digital</p>
            <p className="textCenter">conteúdo digital </p>
            <p className="textOpacity">conteúdo digital</p>
            <p className="textOpacity">conteúdo digital</p>
          </div>
        </div>

        <div
          data-aos="slide-up"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
          data-aos-once="true"
        >
          <div className="box_texto">
            <p className="textOpacity">redes sociais</p>
            <p className="textOpacity">redes sociais</p>
            <p className="textOpacity">redes sociais</p>
            <p className="textCenter">redes sociais</p>
            <p className="textOpacity">redes sociais</p>
            <p className="textOpacity">redes sociais</p>
            <p className="textOpacity">redes sociais</p>
          </div>
        </div>
        <div
          data-aos="slide-up"
          data-aos-duration="400"
          data-aos-easing="ease-in-out"
          data-aos-once="true"
        >
          <div className="box_texto last">
            <p className="textOpacity">consultoria digital</p>
            <p className="textOpacity">consultoria digital</p>
            <p className="textCenter">consultoria digital</p>
            <p className="textOpacity">consultoria digital</p>
            <p className="textOpacity">consultoria digital</p>
          </div>
        </div>
      </div>

      <Footer />
    </Container>
  )
}

export default NovosNegocios
