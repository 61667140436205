/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useLayoutEffect } from 'react'
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  useLocation,
} from 'react-router-dom'

interface ReactProps extends ReactDOMRouteProps {
  isPrivate?: boolean
  component: React.ComponentType
}

const Route: React.FC<ReactProps> = ({ component: Component, ...rest }) => {
  const location = useLocation()
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])
  return (
    <ReactDOMRoute
      {...rest}
      render={() => {
        return <Component {...rest} />
      }}
    />
  )
}

export default Route
