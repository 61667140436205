import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  margin-top: 100px;
  h1 {
    font-family: 'RobotoCondensedBold';
    font-size: 150px;
    line-height: 150px;
    color: #fff;
    z-index: 2;
    position: relative;
    opacity: 0.5;
    text-align: right;
    @media (max-width: 768px) {
      padding: 0 15px;
      font-size: 55px;
      line-height: 70px;
    }
  }
  h1:hover {
    opacity: 1;
  }
  h1.active {
    opacity: 1;
  }

  #people:hover + #marca {
    display: none !important;
    background: #ccc;
  }

  #marc:hover + #marca {
    display: none !important;
    background: #ccc;
  }
  #well:hover + #marca {
    display: none !important;
    background: #ccc;
  }

  img {
    margin-left: -43px;
    margin-top: -213px;
  }
  @media (max-width: 768px) {
    padding: 0 16px;
    img {
      width: 100%;
      margin-top: -113px;
    }
  }
  .hide {
    display: none;
  }
`

export const Box = styled.div``

export const Button = styled.button`
  background: none;
  border: none;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
