import styled from 'styled-components'
import { isMobile } from 'react-device-detect'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: linear-gradient(to bottom, #3703a6, #3703a6, #551e93);
`

export const Content = styled.div`
  max-width: ${isMobile ? '100%' : '1170px'};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1 {
    font-family: 'RobotoCondensedBold';
    font-size: ${isMobile ? '50px' : '140px'};
    color: #f2ae2e;
    width: 100%;
    padding-left: 15%;
    margin-top: ${isMobile ? '20px' : '120px'};
    margin-bottom: 50px;
    text-transform: capitalize;
    @media (max-width: 768px) {
      padding-left: 16px;
      margin-bottom: 0;
    }
  }

  .box_case_texts {
    display: flex;
    flex-direction: column;
    width: ${isMobile ? '100%' : '50%'};
    margin-bottom: 50px;
    @media (max-width: 768px) {
      padding: 0 16px;
      margin-bottom: 25px;
    }
  }
  p {
    font-family: 'RobotoCondensedRegular';
    font-size: 22px;
    color: #fff;
    line-height: 36px;
    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 26px;
    }
  }
  p.subTitle {
    font-family: 'RobotoCondensedBold';
    font-size: ${isMobile ? '30px' : '44px'};
    margin-bottom: 36px;
  }

  .imagensBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-left: 15%;
    img {
      margin: 50px 0;
    }
    @media (max-width: 768px) {
      padding: 0 16px;
      img {
        width: 100%;
        margin: 25px 0;
      }
    }
  }

  .videosBox {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    @media (max-width: 768px) {
      padding: 0 16px;
    }
  }
  .videoItem {
    margin: 50px 0;
    display: flex;
    flex: 1;
    @media (max-width: 768px) {
      margin: 25px 0;
    }
    position: relative;
  }
  .videoItem.last {
    margin: 50px 0 0 0;
    @media (max-width: 768px) {
      margin: 25px 0 0 0;
    }
  }
  .videoItem .loading {
    position: absolute;
    width: 100%;
    text-align: center;
    z-index: 1;
    height: 480px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      height: 120px;
    }
  }

  .boxButtonMore {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    @media (max-width: 768px) {
      margin-top: 50px;
    }
    button {
      background: none;
      border: none;
      font-family: 'RobotoCondensedBold';
      font-size: 44px;
      color: #f2ae2e;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      span {
        font-family: 'RobotoCondensedBold';
        font-size: 28px;
        color: #f2ae2e;
        border-bottom: solid 1px #f2ae2e;
      }
    }
  }
`
