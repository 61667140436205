import { createGlobalStyle } from 'styled-components'

import { isMobile } from 'react-device-detect'
import RobotoCondensedBold from '../assets/fonts/RobotoCondensed-Bold.ttf'

import RobotoCondensedRegular from '../assets/fonts/RobotoCondensed-Regular.ttf'

export default createGlobalStyle`
  @font-face {
    font-family: 'RobotoCondensedBold' ;
    src: url(${RobotoCondensedBold});
  }

  @font-face {
    font-family: 'RobotoCondensedRegular' ;
    src: url(${RobotoCondensedRegular});
  }
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body {
    background: #fff;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    font-family: 'RobotoCondensedRegular'
  }
  #root {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  body, input, button, a, p {
    font-family: 'RobotoCondensedRegular', Arial;
    font-size: 16px;
  }
  p{
    color: #7e7d82;
  }
  a{
    text-decoration: none;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button {
    cursor: pointer;
  }
  .content_modal{
    max-width: ${isMobile ? '100%' : '1170px'};
  }
  .ReactModal__Overlay{
    z-index: 9999;
    position: 'fixed',
  }
  .ReactModal__Content,
  .ReactModal__Content--after-open {
    position: absolute;
    top: 25px !important;
    left: 40px !important;
    right: 40px !important;
    bottom: 40px !important;
    background-color: #3703a6 !important;
    max-width: ${isMobile ? '100%' : '1170px'};
    margin: 0 auto;
    overflow: auto !important;
    @media (max-width: 768px) {
      top: 0 !important;
      left: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
    }
  }

  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
  }
  .modal_title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 22px;
    @media (max-width: 768px) {
      padding: 0;
      align-items: flex-end;
    }
  }
  .modal_title h1, .modal_title button {
    font-family: 'RobotoCondensedBold';
    color: #fff;
    font-size: 22px;
    background: none;
    border: none;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  .modal_title button {
    font-size: 44px;
  }
  .modal_conteudo{
    background: #fff;
    padding: 22px 0;
    margin-top: 22px;
  }
  .textModal {
    margin: 30px;
    color: #8f8b8a;
    font-family: 'RobotoCondensedRegular';
    line-height: 24px;
  }
  .textModal.negrito  {
    font-family: 'RobotoCondensedBold';
    color: #000;
  }

  span {
    font-family: 'RobotoCondensedBold';
    color: #f2ae2e;
  }
  @keyframes mymove {
  from {opacity: 0;}
  to {opacity: .8;}
}
.modal{
  transition: opacity .5s;
}
  .modal.closed {
  opacity: 0;
}
.modal.open {
  opacity: 1;
  animation: mymove 1s ;
}
.modal.will-close {
  opacity: 0;
}

.alice-carousel__dots-item:not(.__custom) {
  background-color: #fff;
}

.alice-carousel__dots-item:not(.__custom):hover, .alice-carousel__dots-item:not(.__custom).__active {
  background-color: #f27289;
}

`
