import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import { BallBeat } from 'react-pure-loaders'

import { isMobile } from 'react-device-detect'

import { useHistory, useLocation } from 'react-router-dom'
import { Container, Content } from './styles'

import MenuMobile from '../../components/Menu/mobile'
import Footer from '../../components/Footer'

import jsonCases from '../../assets/json/cases.json'

const CaseDetails: React.FC = () => {
  const location = useLocation()

  const [viewVideo, setViewVideo] = useState(false)

  const index = location?.state?.index || 0
  const history = useHistory()
  const caseDetails = jsonCases[index]

  return (
    <Container>
      <MenuMobile pageName="cases" />
      <Content>
        <h1>{caseDetails.title}</h1>
        <div className="box_case_texts">
          <p className="subTitle">{caseDetails?.subTitle}</p>
          {caseDetails?.action && (
            <p>
              <span>Ação: </span>
              {caseDetails?.action}
            </p>
          )}
          {caseDetails?.challenges && (
            <p>
              <span>Desafios: </span>
              {caseDetails?.challenges}
            </p>
          )}

          {caseDetails?.solution && (
            <p>
              <span>Solução: </span>
              {caseDetails?.solution}
            </p>
          )}

          {caseDetails?.results && (
            <p>
              <span>Resultados: </span>
              {caseDetails?.results}
            </p>
          )}

          {caseDetails?.core && (
            <p>
              <span>Núcleo: </span>
              {caseDetails?.core}
            </p>
          )}
        </div>
        <div className="imagensBox">
          {caseDetails?.imagens?.map(
            (image, indexImg) =>
              indexImg !== 0 && (
                <img
                  src={`/imagens/cases/${image}`}
                  alt="cuidado e informação"
                />
              ),
          )}
        </div>

        <div className="videosBox">
          {caseDetails?.videos?.map((video: string, indexV: number) => (
            <div
              key={video}
              className={`videoItem ${
                caseDetails?.videos.length - 1 === indexV ? 'last' : ''
              }`}
            >
              {!viewVideo && (
                <div className="loading">
                  <BallBeat color="#fff" loading />
                </div>
              )}

              {!isMobile && (
                <ReactPlayer
                  url={video}
                  controls
                  width="854px"
                  height="480px"
                  onReady={() => setViewVideo(true)}
                />
              )}

              {isMobile && (
                <ReactPlayer
                  url={video}
                  controls
                  width="100%"
                  height="100%"
                  onReady={() => setViewVideo(true)}
                />
              )}
            </div>
          ))}
        </div>

        <div className="boxButtonMore">
          <button type="button" onClick={() => history.push('/cases')}>
            <span>outros</span>
            <span>cases</span>
          </button>
        </div>
      </Content>
      <Footer />
    </Container>
  )
}

export default CaseDetails
