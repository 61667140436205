/* eslint-disable no-use-before-define */
import React from 'react'
import { Switch } from 'react-router-dom'

import Route from './Route'

import Home from '../pages/Home/index'
import Sobre from '../pages/Sobre'
import ItensMenu from '../pages/ItensMenu'
import Contato from '../pages/Contato'
import OQueFazemos from '../pages/OQueFazemos'
import NovosNegocios from '../pages/NovosNegocios'
import Pessoas from '../pages/Pessoas'
import Marcas from '../pages/Marcas'
import Cases from '../pages/Cases'
import CaseDetails from '../pages/CaseDetails'
import Wellness from '../pages/Wellness'

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Home} />
    <Route path="/sobre" component={Sobre} />
    <Route path="/oquefazemos" component={OQueFazemos} />
    <Route path="/novosnegocios" component={NovosNegocios} />
    <Route path="/itensMenu" component={ItensMenu} />
    <Route path="/contato" component={Contato} />
    <Route path="/pessoas" component={Pessoas} />
    <Route path="/marcas" component={Marcas} />
    <Route path="/wellness" component={Wellness} />
    <Route path="/cases" component={Cases} />
    <Route path="/caseDetails" component={CaseDetails} />
  </Switch>
)

export default Routes
