import styled from 'styled-components'

import { isMobile } from 'react-device-detect'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #3703a6;

  .quemfaz {
    width: 100%;
    margin-top: 120px;
    @media (max-width: 768px) {
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }
  text {
    fill: #fff;
    transform: translate3d(0, 0, 0);
    width: 100%;
    font-size: ${isMobile ? '70px' : '140px'};
    height: 100%;
    font-family: 'RobotoCondensedBold';
    opacity: 0.3;
  }

  textPath {
    transform: translate3d(0, 0, 0);
    @media (max-width: 768px) {
      font-size: 100px;
    }
  }

  .perfil {
    width: 100%;
    background-image: linear-gradient(
      to bottom,
      #3703a6 1%,
      #f2ae2e,
      #f2ae2e,
      #f2ae2e 85%,
      #3703a6
    );
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media (max-width: 768px) {
      padding: 0 5%;
    }
  }
`

export const Content = styled.div`
  max-width: ${isMobile ? '100%' : '1170px'};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? 'center' : 'flex-start'};
  flex-direction: column;
  h1 {
    font-family: 'RobotoCondensedBold';
    font-size: ${isMobile ? '70px' : '140px'};
    color: #fff;
    width: 100%;
    padding-left: 15%;
    margin-top: 100px;
    margin-bottom: 50px;
    @media (max-width: 768px) {
      margin: 20px 0;
      padding: 0 16px;
    }
  }
  .boxTexto {
    max-width: ${isMobile ? '100%' : '1170px'};
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: ${isMobile ? 'center' : 'flex-start'};
    flex-direction: column;
    padding-left: 30%;
    padding-right: 56px;
    @media (max-width: 768px) {
      padding: 0 16px;
    }
  }
  p {
    margin-top: 50px;
    font-size: 18px;
    line-height: 30px;
    font-family: 'RobotoCondensedRegular';
    color: #fff;
  }
  .textoDestaque {
    font-family: 'RobotoCondensedRegular';
    font-size: 18px;
    line-height: 30px;
    color: #fff;
    width: 100%;
    margin-top: ${isMobile ? '30px' : '50px'};
    max-width: 600px;
    b {
      color: #f2ae2e;
    }
    @media (max-width: 768px) {
      margin-top: 20px;
      padding: 0;
    }
  }
`

export const Perfil = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Descricao = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  .box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 8px 0 125px;
    width: 100%;
    overflow: hidden;
    @media (max-width: 768px) {
      padding: 0;
    }
  }

  .box_descricao {
    height: 18%;
    overflow: hidden;
    transition: height 0.3s;
    -webkit-mask-image: -webkit-gradient(
      linear,
      left 60%,
      left bottom,
      from(#000),
      to(transparent)
    );
    @media (max-width: 768px) {
      height: 88px;
    }
  }

  .box_descricao.complete {
    height: 100%;
    transition: height 0.3s;
    -webkit-mask-image: none;
  }

  button {
    background: none;
    border: none;
    margin-top: 10px;
  }

  p.descricao {
    width: 80%;
    margin-top: 25px;
    line-height: 30px;
    @media (max-width: 768px) {
      line-height: 28px;
      width: 100%;
    }
  }
  p.nome {
    margin-top: 150px;
    font-family: 'RobotoCondensedBold';
    font-size: 25px;
    color: #f2ae2e;
    text-transform: uppercase;
    @media (max-width: 768px) {
      margin-top: 20px;
    }
  }
  p.nome2 {
    margin-top: 150px;
    font-family: 'RobotoCondensedBold';
    font-size: 25px;
    color: #3703a6;
    text-transform: uppercase;
    @media (max-width: 768px) {
      margin-top: 20px;
    }
  }
  .cargo {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  p.funcao {
    font-family: 'RobotoCondensedBold';
    font-size: 20px;
    color: #fff;
    text-transform: uppercase;
    padding-right: 10px;
    margin-top: 0;
  }
  p.descricao_funcao {
    font-family: 'RobotoCondensedRegular';
    font-size: 18px;
    color: #fff;
    text-transform: lowercase;
    margin-top: 0;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    img {
      width: 100%;
    }
  }
`
export const Frase = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  max-width: 870px;
  p {
    font-family: 'RobotoCondensedBold';
    font-size: 30px;
    color: #3703a6;
    margin: 150px 0 150px;
    text-align: center;
    line-height: 45px;
  }
  p.last {
    margin: 100px 0 0 0;
  }
`
