import styled from 'styled-components'

import { isMobile } from 'react-device-detect'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: linear-gradient(to bottom, #3703a6, #3703a6, #551e93);
`

export const Content = styled.div`
  max-width: ${isMobile ? '100%' : '1170px'};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? 'center' : 'flex-start'};
  flex-direction: column;
  padding: 0 16px;
  h1 {
    font-family: 'RobotoCondensedBold';
    font-size: ${isMobile ? '70px' : '140px'};
    color: #fff;
    width: 100%;
    padding-left: ${isMobile ? '8px' : '15%'};
    margin-top: ${isMobile ? '20px' : '120px'};
    margin-bottom: ${isMobile ? '0' : '50px'};
  }

  .box_contato {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .box_contato_1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: ${isMobile ? '16px' : '0'};
    p {
      font-size: ${isMobile ? '20px' : '40px'};
      line-height: ${isMobile ? '29px' : '58px'};
      font-family: 'RobotoCondensedBold';
      color: #fff;
    }
    width: ${isMobile ? '100%' : '50%'};
    margin-top: ${isMobile ? '30px' : '50px'};
    margin-bottom: ${isMobile ? '30px' : '100px'};
  }
  .box_contato_2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: ${isMobile ? '16px' : '0'};
    p {
      font-size: ${isMobile ? '18px' : '25px'};
      line-height: ${isMobile ? '29px' : '38px'};
      font-family: 'RobotoCondensedRegular';
      color: #f2ae2e;
    }
    width: ${isMobile ? '100%' : '50%'};
  }
`
