import styled from 'styled-components'

import { isMobile } from 'react-device-detect'

interface CasesProps {
  isLast: boolean
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: linear-gradient(to bottom, #3703a6, #3703a6, #551e93);
`

export const Content = styled.div`
  max-width: ${isMobile ? '100%' : '1170px'};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? 'center' : 'flex-start'};
  flex-direction: column;

  h1 {
    font-family: 'RobotoCondensedBold';
    font-size: ${isMobile ? '70px' : '140px'};
    color: #fff;
    width: 100%;
    padding-left: 15%;
    margin-top: ${isMobile ? '0' : '120px'};
    @media (max-width: 768px) {
      padding-left: 16px;
    }
  }
  .anim {
    width: 100%;
  }
  .tituloPessoas1 {
    margin: 0;
    opacity: 0.4;
    margin-top: ${isMobile ? '-60px' : '-100px'};
  }

  .tituloPessoas2 {
    margin: 0;
    opacity: 0.2;
    margin-top: ${isMobile ? '-52px' : '-92px'};
  }
  .perfil {
    margin-top: ${isMobile ? '-30px' : '-70px'};
  }

  .boxButtonMore {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    button {
      background: none;
      border: none;
      font-family: 'RobotoCondensedBold';
      font-size: 44px;
      color: #f2ae2e;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      span {
        font-family: 'RobotoCondensedBold';
        font-size: 28px;
        color: #f2ae2e;
        border-bottom: solid 1px #f2ae2e;
      }
    }
  }
`
export const Perfil = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`

export const Descricao = styled.div<CasesProps>`
  width: 100%;
  display: flex;
  margin-bottom: ${props => (props.isLast ? '0' : '150px')};
  flex-direction: row;
  .caseImagem {
    width: ${isMobile ? '50%' : '100%'};
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      img {
        width: 100%;
        height: 100%;
      }
    } 
  }  
  @media (max-width: 768px) {
    padding: 0 8px;
    margin-bottom: ${props => (props.isLast ? '0' : '50px')};

  }
}

  .box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
    width: ${isMobile ? '50%' : '100%'};
    overflow: hidden;

    padding: 0 0 0 130px;
    @media (max-width: 768px) {
      padding: 0 0 0 16px;
    }
  }

  .box_descricao {
    height: 0%;
    overflow: hidden;
    transition: height 1s;
  }

  .box_descricao.complete {
    height: 100%;
    transition: height 1s;
  }

  button {
    background: none;
    border: none;
    margin-top: 10px;
  }
  p {
    font-size: 18px;
    line-height: 36px;
    font-family: 'RobotoCondensedRegular';
    color: #fff;
  }
  p.descricao {
    width: ${isMobile ? '90%' : '80%'};
  }
  p.nome {
    font-family: 'RobotoCondensedBold';
    font-size: 44px;
    color: #f2ae2e;
    text-transform: capitalize;
    @media (max-width: 768px) {
      font-size: 22px;
    }
  }
  p.nome2 {
    font-family: 'RobotoCondensedBold';
    font-size: 44px;
    color: #3703a6;
    text-transform: uppercase;
    @media (max-width: 768px) {
      font-size: 22px;
    }
  }
  .cargo {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
  }
  p.funcao {
    font-family: 'RobotoCondensedBold';
    font-size: 22px;
    color: #fff;
    text-transform: lowercase;
    padding-right: 10px;
    @media (max-width: 768px) {
      font-size: 20px;
      line-height: 25px;
    }
  }
`
