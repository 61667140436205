import React, { useEffect, useRef } from 'react'
import { CgArrowRight } from 'react-icons/cg'
import { useHistory } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import ReactPlayer from 'react-player'
import Depoimentos from '../../components/Depoimentos'
import {
  Container,
  Content,
  Banner,
  DescriptionText,
  DescriptionText2,
  Button,
  Title,
} from './styles'
import videoEvva from '../../assets/videos/video-home.mp4'
import videoEvvaMobile from '../../assets/videos/video-home-mobile.mp4'

import MenuMobile from '../../components/Menu/mobile'
import Footer from '../../components/Footer'

import Clientes from '../../components/Clientes'
import ClientesMobile from '../../components/ClientesMobile'
import Estrategia from '../../components/Estrategia'

const Home: React.FC = () => {
  const playerRef = useRef<ReactPlayer>(null)
  const history = useHistory()
  const handleScroll = (): void => {
    const textPath = document.querySelector('#text-path')
    const homeImageAnim1 = document.querySelector('#homeImageAnim1')
    // const homeImageAnim2 = document.querySelector('#homeImageAnim2')

    const h = document.documentElement
    const b = document.body
    const st = 'scrollTop'
    const sh = 'scrollHeight'
    const soma = isMobile ? 1300 : 2400
    const multi = isMobile ? 62 : 61

    const multiImage1 = isMobile ? 1 : 4

    const percent =
      ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100
    textPath?.setAttribute('startOffset', String(-percent * multi + soma))

    homeImageAnim1?.setAttribute(
      'style',
      `-webkit-transform: translate3d(-8px, ${String(
        -percent * multiImage1,
      )}px,0)`,
    )
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const handleReady = (): void => {
    playerRef.current?.getInternalPlayer()?.play()
  }

  return (
    <Container>
      <MenuMobile pageName="home" />

      <Banner>
        {isMobile ? (
          <>
            <p className="title_home first">um novo jeito</p>
            <p className="title_home">de construir imagem</p>
            <p className="title_home last">e reputação</p>
          </>
        ) : (
          <>
            <p className="title_home first">um novo jeito de</p>
            <p className="title_home">construir imagem</p>
            <p className="title_home last">e reputação</p>
          </>
        )}

        <ReactPlayer
          onReady={handleReady}
          url={isMobile ? videoEvvaMobile : videoEvva}
          // controls
          width="100%"
          height={isMobile ? 360 : 600}
          loop
          playing
          volume={0}
          playsinline
          muted
        />
        <DescriptionText>
          Somos uma agência de assessoria de imprensa e relações públicas, com
          bagagem de mais de 12 anos de atuação no mercado e método de trabalho
          pioneiro e adaptado às mudanças da comunicação.
        </DescriptionText>
      </Banner>

      <div className="boxButton">
        <Button
          type="button"
          onClick={() => history.push('/sobre')}
          color="#f2ae2e"
        >
          <span>saiba mais</span>
          <div color="#f2ae2e">
            <CgArrowRight size={isMobile ? 30 : 30} color="#f2ae2e" />
          </div>
        </Button>
      </div>

      <svg
        width="100%"
        height={isMobile ? '200px' : '500px'}
        viewBox="0 0 1098.72 89.55"
      >
        <path
          className="text-on-path__curve"
          d="M.13 40.06s340-89 721 0 720-1 720-1"
          fill="none"
          stroke="none"
          id="curve"
        />
        <text width="100%">
          <textPath
            id="text-path"
            alignmentBaseline="auto"
            xlinkHref="#curve"
            startOffset={isMobile ? '200px' : '600px'}
          >
            Comunicação eficiente, criativa e assertiva
          </textPath>
        </text>
      </svg>

      {isMobile ? <ClientesMobile /> : <Clientes />}

      <DescriptionText2 color="#3703a6">
        trabalhamos marcas como pessoas e pessoas como marcas
      </DescriptionText2>

      <Button
        type="button"
        onClick={() => history.push('/sobre')}
        color="#3703a6"
      >
        <span>saiba mais</span>
        <div>
          <CgArrowRight size={isMobile ? 30 : 30} color="#3703a6" />
        </div>
      </Button>

      <Content>
        <Estrategia />
      </Content>

      <Title>depoimentos</Title>
      <Depoimentos />

      <Footer />
    </Container>
  )
}

export default Home
