import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    padding: 0 15px;
  }
`

export const Title = styled.p`
  font-family: 'RobotoCondensedRegular';
  font-size: 30px;
  text-align: center;
  color: #fff;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`
export const SubTitle = styled.p`
  font-family: 'RobotoCondensedRegular';
  font-size: 20px;
  text-align: center;
  color: #f27289;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`

export const Description = styled.p`
  font-family: 'RobotoCondensedRegular';
  font-size: 18px;
  color: #fff;
  max-width: 800px;
  text-align: center;
  margin-top: 40px;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    margin-top: 30px;
    padding-right: 20px;
  }
`
