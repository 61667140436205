import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Container, Box, Button } from './styles'

import Marcas from '../../assets/imagens/home/marcas.png'
import Pessoas from '../../assets/imagens/home/pessoas.png'
import Wellness from '../../assets/imagens/home/wellness.png'

const Clientes: React.FC = () => {
  const history = useHistory()

  const [isHovering, setIsHovering] = useState({
    image: Pessoas,
    active: 'pessoas',
  })

  const handleHover = useCallback((image: string, text: string) => {
    setIsHovering({ image, active: text })
  }, [])

  return (
    <Container>
      <Box>
        <Button type="button" onClick={() => history.push('/pessoas')}>
          <h1
            id="people"
            onMouseEnter={() => handleHover(Pessoas, 'pessoas')}
            className={isHovering.active === 'pessoas' ? 'active' : ''}
          >
            pessoas
          </h1>
        </Button>
        <Button type="button" onClick={() => history.push('/marcas')}>
          <h1
            id="marc"
            onMouseEnter={() => handleHover(Marcas, 'marca')}
            className={isHovering.active === 'marca' ? 'active' : ''}
          >
            marcas
          </h1>
        </Button>
        <Button type="button" onClick={() => history.push('/wellness')}>
          <h1
            id="well"
            onMouseEnter={() => handleHover(Wellness, 'wellness')}
            className={isHovering.active === 'wellness' ? 'active' : ''}
          >
            wellness
          </h1>
        </Button>
      </Box>
      <Box>
        <img src={isHovering.image} alt="well" />
      </Box>
    </Container>
  )
}

export default Clientes
