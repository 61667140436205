import styled from 'styled-components'
import { isMobile } from 'react-device-detect'

export const Container = styled.div`
  max-width: ${isMobile ? '100%' : '1170px'};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: 'center';
  padding: 0 0 16px 0;
`
export const Content = styled.div`
  border: 0;
  padding: 0;
  color: #fff;
  width: 100%;
  max-width: ${isMobile ? '100%' : '1170px'};
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .one,
  .two,
  .three {
    background-color: #f27289;
    height: 13px;
    width: 13px;
    margin: 12px auto;
    transition-duration: 0.5s;
    border-radius: 13px;
    margin-right: 5px;
    margin-top: 16px;
  }
  .nameMenu {
    font-size: ${isMobile ? '17px' : '20px'};
    font-family: 'RobotoCondensedBold';
    border-bottom: solid 1px;
    color: #fff;
    text-transform: lowercase;
    line-height: 19px;
  }

  .menu ul li a {
    color: #f27289;
  }
  .menu.on .one,
  .menu.on .two,
  .menu.on .three {
    background-color: #fff;
  }
  .menu-toggle.on .one {
    transform: rotate(45deg) translate(7px, 7px);
  }

  .menu-toggle.on .two {
    transform: rotate(-45deg) translate(3px, -4px);
  }

  .menu-toggle.on .three {
    transform: rotate(-45deg) translate(8px, -9px);
  }

  .menu-toggle {
    width: 80px;
    height: 50px;
    border: none;
    background: none;
    position: fixed;
    right: 10%;
    z-index: 9;
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: 768px) {
      width: 75px;
      height: 50px;
      right: 6%;
    }
  }
  .menu_top {
    width: 100%;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`
export const Logo = styled.image`
  background-image: url({logo});
`
