import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 110px 0;
  @media (max-width: 768px) {
    margin: 0;
  }

  p {
    font-family: 'RobotoCondensedBold';
    font-size: 60px;
    color: #fff;
  }
  .text_estrategia {
    opacity: 0.5;
    text-align: left;
    font-size: 150px;
    @media (max-width: 768px) {
      padding: 0 15px;
      font-size: 55px;
      opacity: 1;
      width: 90%;
      margin-left: 15px;
    }
  }

  p.text_estrategia:hover {
    opacity: 1;
  }
  .boxImagens {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex: 1;
  }
  .boxImagens1 {
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
  }
  .boxImagens1 img {
    width: 85%;
    position: absolute;
  }
  .img1_b {
    left: 5%;
  }
  .img1_c {
    left: 10%;
  }
  .img1_d {
    left: 15%;
  }
  .boxImagens2 {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  .boxImagens2 div {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .boxImagens2 img {
    width: 90%;
  }

  @media (max-width: 768px) {
    p {
      font-size: 30px;
    }
  }
`
export const Button = styled.button`
  background: none;
  border: none;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15%;

  @media (max-width: 768px) {
    padding: 0;
  }
`
